.Servicios{
    background: #053740;
	font-family: 'Open Sans', sans-serif;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#20a6bb', endColorstr='#25bad0', GradientType=0 ); /* IE6-9 */
	padding: 75px;
}

h2 {
    line-height: 20px;
	color: #25BAD0;
	margin-bottom: 30px;
	font-size: 40px;
	font-weight: 400;
	text-transform: uppercase;
	padding-bottom: 10px;
}

h3{
	color: #25BAD0 !important;
	font-size: 20px;
	margin-top: 0px;
}
.row p {
	color: #ccc;
	
}
.iconos{
	font-size: 90px;
	color: #25BAD0;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 30px;
	display: flex;
}
i{
	font-size: 100px;
	color: #25BAD0;
	padding: 20px;
	padding-bottom: 30px;
}
.Service-title{
	font-size: 42px;
	padding-bottom: 60px;
	/*color: rgba(255,255,255,0.8);*/
	color: #25BAD0;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 5px;
    padding-left: 15px;
}