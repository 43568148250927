.top-nav-collapse{
    background-color: #03252b !important;
    transition: all 0.8s;
    height: 73px;
}

.top-nav-collapse .show {
    background-color: #03252b !important;
    padding: 5px;
}


a:hover, a:focus{
    color: #25BAD0 !important;
    cursor: pointer;
}

a {
    padding: 10px;
    color: #ccc !important;
}
 a:active{
     color:#25BAD0 !important;
 }

 Link:hover {
     cursor: pointer !important;
 }
 
 .navbar-toggler {
    /* padding: .25rem .75rem; */
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    padding-right: 40px;
    
}
.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 0rem !important;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}