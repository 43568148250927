.intro h1 {
    font-size: 42px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgba(255,255,255,0.9);
    background:inherit;
    
}

.intro {
    display: table;
    width: 100%;
    height: auto;
    
    color: #fff;
    background: url(../../../assets/images/shutterstock_349563746.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.intro .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.intro .btn-lg {
    color: #333;
    background-color: #25BAD0;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
}
.intro .btn-default {
    color: #25BAD0;
    background-color: transparent;
    border: 2px solid #25BAD0;
    padding: 12px 20px;
    margin: 0;
    font-size: 15px;
    border-radius: 0;
    text-transform: uppercase;
}



.intro .overlay {
    padding: 100px 0;
    background: -moz-linear-gradient(top, rgba(5,55,64,1) 0%, rgba(5,55,64,0.6) 100%);
    background: -webkit-linear-gradient(top, rgba(5,55,64,1) 0%, rgba(5,55,64,0.6) 100%);
    background: linear-gradient(to bottom, rgba(5,55,64,1) 0%, rgba(5,55,64,0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#053740', endColorstr='#99053740', GradientType=0 );
}
.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.intro-text {
    padding-top: 150px;
    padding-bottom: 150px;
}
.intro .brand {
    color: #25BAD0;
    font-weight: 700;
}
.intro .intro-text p {
    font-size: 18px;
    margin-bottom: 40px;
    margin-top: 20px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.9);
    
}
.intro.btn-default:hover, .intro .btn-default:focus, .intro.btn-default.focus, .intro.btn-default:active, .intro.btn-default.active {
    color: #053740;
    background-color: #25BAD0;
    border-color: #25BAD0;
}
.Logo {
    background-image: url(../../../assets/images/FUTURODIGITAL-sin-fondo.png);
  }