.filtr-item {
  padding: 1rem;
}
.item-desc {
  background-color: rgba(0,0,0,0.5);
  color: white;
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  padding: 1rem;
  z-index: 1;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
}


/* Filter controls */
.simplefilter, .multifilter, .sortandshuffle, .search-row {
padding-left: 0;
}
.simplefilter li, .multifilter li, .sortandshuffle li {
  color: #25BAD0;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  padding: 1rem 2rem;
  background-color: transparent;
    border: 2px solid #25BAD0;
}
.fltr-controls{
  width: 210px !important;
}

.simplefilter li.active {
  background-color: #25BAD0;
  color: black;
}

.simplefilter li:hover {
  color: white;
}

/* Colored divs */
.block {
box-shadow: 0 1px 4px rgba(0,0,0,.75) inset;
color: white;
text-align: center;
height: 80px;
position: relative;
}
.block > .text {
display: inline-block;
font-size: 24px;
font-weight: bold;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
}
.block.xs {
height: 40px;
}
.block.md {
height: 120px;
}
.block.lg {
height: 160px;
}


/* Helpers */
.push-down {
margin-bottom: 50px;
}
Dialog {
  width: 200px;
  height: 100px;
}

Dialog {
  background-color: #25BAD0
}

.filtr-container,
.color-container-1,
.color-container-2,
.color-container-3,
.color-container-4 {
transition: height 500ms ease-out;
}

.Descripcion p{
  color: #000 !important;
  text-align: justify !important;
  padding: 0 !important;
}
 .port-gallery i, .fa {
   size: 2x !important;
 }

.boton{
  background: rgb(42, 151, 179) !important;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
  color: white !important;
  display: block;
  position: relative;
  height: 50px;
  line-height: 50px;
  width: 100%;
  padding-left: 100px;
  text-align: left;
  transition: all .5s;
  font-size: 300;
  text-transform: none !important;
}

.boton span {
  cursor: pointer;
  
  transition: 0.5s;
}
.boton:before {
  display: block;
  position: absolute;
  left: 1rem; 
  height: 50px;
  line-height: 50px; 
}

.boton span:after{
  content: '\00bb';
  font-family: FontAwesome;
  position: absolute;
  opacity: 0;
  top: 25%;
  left: 90%;
  
  size: 100px !important;
  transition: 0.5s;
  border: 1px solid transparent;
}

.boton span:visited{
  border: 1px solid transparent;
}
.boton:hover span {
  padding-right: 25px;
  
}

.boton:hover span:after {
  opacity: 1;
  right: 0;
}
.boton:visited{
  border: 1px solid transparent;
}
.boton:active{
  border: 1px solid transparent;
}
.boton:focus{
  border: 1px solid transparent;
}
a:active{
  border: 1px solid transparent;
}
a:focus{
  border: 1px solid transparent;
}
a:visited{
  border: 1px solid transparent;
}


.tituloDownload{
  background: transparent !important;
  color: rgb(42, 151, 179) !important;
  border: none;
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 25px !important;
  width: 100%;
  margin-top: 0%;
}
.subtituloDownload{
  background: transparent !important;
  color: rgb(42, 151, 179) !important;
  border: none;
  width: 100%;
  text-align: center !important;
  text-decoration: none !important;
  font-size: 15px !important;
}
.icono:hover{
  color:white !important;
}
.tituloD{
  background: rgb(42, 151, 179) !important;
  color: white !important;
}
.navbar {
    
  
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding:0px; 
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  /* flex-basis: 100%; */
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  /* padding: .25rem .75rem; */
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px rgb(42, 151, 179) !important;
  border-radius: .25rem;
  margin-right: 10px;
  padding-right: 11px !important;
}