.About {
    background: rgb(32,166,187); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(32,166,187,1) 0%, rgba(37,186,208,1) 50%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(32,166,187,1) 0%, rgba(37,186,208,1) 50%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(32,166,187,1) 0%, rgba(37,186,208,1) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#20a6bb', endColorstr='#25bad0', GradientType=0 ); /* IE6-9 */
    padding: 50px;
    font-family: 'Open Sans', sans-serif;
}

.container {
    font-size: 18px;
}
.div2 p {
    color: white !important;
    text-align: justify;
    
}

.Title {
    color: rgba(255,255,255,0.8);
    font-size: 42px;
    padding-bottom: 60px;
}

h4 {
    color: #07373f;
    text-align: left;
}

img{
    float:left;
    margin-right: 60px;
    margin-bottom: 60px;
    max-width: 100%;
}

.div2{
    justify-content: left !important;
    align-content: left !important;
    align-items: left !important;
    
}





